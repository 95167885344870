import React from 'react';
import Breadcrumb from 'components/Breadcrumb';
import {
  // Container
  BackgroundEventContainer,
  // Footer Breadcrumb
  FooterBreadcrumbContainer,
  FooterBreadcrumbContent,
  // Main Body
  MainBodyContainer,
} from 'components/Layouts/BackgroundEvent/styles';
import Header from 'components/Layouts/Generic/Header';
import Body from 'components/Layouts/Generic/Body';
import SecurityHeader from "../../Security/Security";

const Generic = (props) => {
  const { title, body, location } = props;

  return (
    <BackgroundEventContainer>
      <Breadcrumb location={location} isBackgroundEvent={true} />
      <Header title={title} style={{ paddingBottom: '120px' }} />
        <SecurityHeader />
      <MainBodyContainer>
        <Body html={body} />
      </MainBodyContainer>
      <FooterBreadcrumbContainer>
        <FooterBreadcrumbContent>
          <Breadcrumb isBackgroundEvent={true} removeContainer={true} />
        </FooterBreadcrumbContent>
      </FooterBreadcrumbContainer>
    </BackgroundEventContainer>
  );
};

export default Generic;
