import styled from 'styled-components';
import React from 'react';

const BodyContainer = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding-bottom: 100px;
  text-align: left;

  p:first-of-type {
    font-size: 20px;
  }

  a {
    display: inline;
    text-decoration: underline;
    color: ${props => props.theme.colors.blue};
    transition: color 0.3s ease;

    :hover {
      color: ${props => props.theme.colors.primary};
    }
  }

  p {
    line-height: 1.5;
  }

  img {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  > * {
    margin-bottom: 30px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  > div {
    display: flex;
    margin-top: 20px;

    > * {
      width: 50%;
    }

    img {
      width: 100%;
      margin: 0;
    }
  }
`;

const Body = ({ html }) => {
  return html ? (
    <BodyContainer
      className='body-container'
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : (
    <></>
  );
};

export default Body;
