import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router'
import gtmEvents from '../../../../utils/functions/gtmFunctions.js';
import {
  HeaderContainer,
  HeaderContent,
  HeaderTitle,
  HeaderOverline,
} from './styles';

const Header = ({ title, overline, style }) => {
  useEffect(() => {
    const unlisten = globalHistory.listen((location) => {
      gtmEvents.sendVirtualEventPageView(title, overline, location.location);
    });
    return function cleanup() {
      unlisten()
    }
  }, [title, overline])
  return (
    <HeaderContainer className='header__container' style={style}>
      <HeaderContent>
        {overline && (
          <HeaderOverline elementTheme='h4'>{overline}</HeaderOverline>
        )}
        {title && <HeaderTitle elementTheme='h1'>{title}</HeaderTitle>}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
